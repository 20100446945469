@media (min-width: 1281px) {
}

/* 
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */

@media (min-width: 1025px) and (max-width: 1280px) {
}

/* 
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */

@media (min-width: 768px) and (max-width: 1024px) {
}

/* 
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */

@media (min-width: 481px) and (max-width: 767px) {
  .movie-card .btn {
    padding: 08px !important;
    font-size: 12px;
  }
  .user-profile-page .transactions .book-ticket {
    margin: 10px 10px;
  }
}

/* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */

@media (min-width: 320px) and (max-width: 480px) {
  .exp-page .heading {
    /* font-size: 50px !important; */
    text-align: center !important;
  }

  .user-profile-page .transactions .book-ticket {
    margin: 10px 10px !important;
  }
  .user-profile-page .transactions .movie-img {
    padding-left: 05px;
  }

  .user-profile-page .transactions .ticket {
    padding: 10px;
  }
}

/* 
    ##Device = Tablets and Mobile (Portrait)
    ##Screen = B/w 320px to 1024px
  */

@media (min-width: 320px) and (max-width: 1024px) {
  .movie-box .movie-img {
    height: 200px !important;
    width: 100%;
  }

  .movie-content .left-content {
    width: 100% !important;
    height: 35px;
  }

  .movie-content .right-content {
    width: 100% !important;
  }

  .movie-content .movie-rate {
    width: 70%;
    height: 30px;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  .movie-content .movie-lang {
    padding-left: 10px;
    float: right;
    width: 30%;
    margin-top: -45px;
  }

  .no-page .heading {
    width: 100% !important;
  }

  .no-page .message {
    width: 100% !important;
    text-align: center;
  }
}
